<div class="relative">
    <!-- Messages (Scrollable content) -->
    <div class="flex-1 overflow-y-auto" #messageContainer>
        @if (messages.length > 0) {
            <flowbot-messages [messages]="messages" [loading]="loading"
                              [reasoningActive]="reasoningActive"
                              [reasoning]="reasoning"
                              [suggestions]="questionSuggestions"
                              [sources]="sources"
                              (emitChatContent)="chatRequest($event)"
            />
            <!-- Place Suggestions Here-->
        } @else {
            <new-chat-hints />
        }
    </div>
    <!-- Input (Sticky at the bottom) -->
    <div class="sticky bottom-0 w-full px-6 pb-4 z-99999">
        <flowbot-input class="sticky bottom-0" (newMessage)="chatRequest($event)" [disabled]="disabled"
        [searchInput]="searchInput()" />
    </div>
</div>





