import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, effect,
    EventEmitter,
    inject, input,
    Input, InputSignal,
    Output, signal,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { NgForOf } from '@angular/common';

@Component({
    selector: 'flowbot-suggestions',
    imports: [MatButton, NgForOf],
    templateUrl: './flowbot-suggestions.component.html',
    styleUrl: './flowbot-suggestions.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowbotSuggestionsComponent {
    @Input() questionSuggestions: { content: string; title: string }[] = [];

    @Output() suggestionClicked = new EventEmitter<string>();
    private cdr = inject(ChangeDetectorRef);

    constructor() {
        effect(() => {
            this.cdr.detectChanges();
        });
    }

    onSuggestionClick(content: string): void {
        console.log(content);
        this.suggestionClicked.emit(content);

    }
}
