import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
} from '@angular/core';
import { NgForOf } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'flowbot-sources',
    imports: [NgForOf, MatIconButton],
    templateUrl: './flowbot-sources.component.html',
    styleUrl: './flowbot-sources.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowbotSourcesComponent {
    @Input() sources: { fileName: string; pageNumber: number; fileId: string }[] = [];
    @Output() sourceClicked = new EventEmitter<number>();
    private _router = inject(Router);
    private _activatedRoute = inject(ActivatedRoute);
    private cdr = inject(ChangeDetectorRef);

    groupedSources: { fileName: string; pageNumbers: number[]; prefix: string }[] = [];

    ngOnChanges(): void {
        this.groupSources();
    }

    private groupSources(): void {
        const grouped = new Map<string, { fileName: string; pageNumbers: number[]; prefix: string; fileId: string }>();

        this.sources?.forEach(({ fileName, pageNumber, fileId }) => {
            const key = fileName?.toLowerCase();
            const isPdf = fileName?.toLowerCase().endsWith('.pdf');

            if (!grouped.has(key)) {
                const prefix = isPdf ? 'pages:' : 'rows:';
                grouped.set(key, { fileName, pageNumbers: [], prefix, fileId });
            }

            const adjustedPageNumber = isPdf ? pageNumber + 1 : pageNumber;
            grouped.get(key)?.pageNumbers.push(adjustedPageNumber);
        });

        this.groupedSources = Array.from(grouped.values());
    }

    onSourceClick(source): void {
        const url = this._router.serializeUrl(
            this._router.createUrlTree(['/file-manager/details', source.fileId])
        );

        window.open(url, '_blank');
    }
}
