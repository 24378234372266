@if(questionSuggestions.length > 0){
    <div class="flex flex-row gap-3 w-full flex-wrap">
        <button
            *ngFor="let suggestion of questionSuggestions"
            mat-flat-button
            class="suggestion-button"
            (click)="onSuggestionClick(suggestion.content)"
        >

            <mat-icon class="material-symbols-rounded">lightbulb</mat-icon>
            {{ suggestion.title }}
        </button>

    </div>
    <p class="text-sm font-semibold text-secondary">Suggestions may not always be relevant or return a response.</p>
}
