<div class="sources-container flex flex-wrap gap-3 text-secondary text-sm">
    @for (source of groupedSources; track source) {
        <span class="py-0.5 px-3 rounded bg-gray-200 flex flex-col"
        >
            <a href="javascript:void(0)" (click)="onSourceClick(source)">{{ source.fileName }}</a>
            <!--<span class="flex flex-row gap-1 items-center">
                {{ source.prefix }}
                <div class="flex flex-row gap-0.5">
                @for (page of source.pageNumbers; track page) {
                    <a href="javascript:void(0)" class="text-xs p-1 text-primary" (click)="onSourceClick(page)">
                        {{ page }}
                    </a>
                }
                    </div>
            </span>-->
        </span>
    }
</div>
