import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import {FlowPlatformService} from "../platform/platform.service";

@Injectable({
	providedIn: 'root'
})
export class HotkeyService {
	private modifierKey: string;
	

	constructor(private platformService: FlowPlatformService) {
		this.modifierKey =  this.platformService.osName === 'os-mac' ? '⌘' : 'Ctrl';
	}

	getModifierKey(): string {
		return this.modifierKey;
	}

	getKey(key: string): string {
		if (key == '↵') {
			return 'Enter'.toLowerCase();
		}
		return key.toLowerCase();
	}
}
