import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import {HotkeyService} from "../../services/hotkey/hotkey.service";


@Directive({
	standalone: true,
	selector: '[flowHotkey]'
})
export class HotkeyDirective implements OnInit {
	@Input('flowHotkey') hotkey!: string;
	@Input() modifiers: ('ctrl' | 'shift' | 'alt')[] = [];
	@Input() showModifiers: boolean = true;

	constructor(
		private el: ElementRef,
		private hotkeyService: HotkeyService
	) {

	}

	ngOnInit() {
		if (!this.showModifiers) return;

		const platformModifier = this.hotkeyService.getModifierKey(); // '⌘' or 'Ctrl'
		const keyLabel = this.hotkey.toUpperCase();

		const visualParts: string[] = [];

		// Always include 'ctrl' / 'meta' if present
		if (this.modifiers.includes('ctrl')) {
			visualParts.push(platformModifier);
		}

		// Show arrow ↑ for shift, literal 'Alt' for alt
		if (this.modifiers.includes('shift')) {
			visualParts.push('⇧');
		}

		if (this.modifiers.includes('alt')) {
			visualParts.push('Alt');
		}

		visualParts.push(keyLabel);

		this.el.nativeElement.innerText += ` ${visualParts.join(' ')}`;
	}

	@HostListener('window:keydown', ['$event'])
	handleKeydown(event: KeyboardEvent) {
		const key = event.key.toLowerCase();
		const expectedKey = this.hotkeyService.getKey(this.hotkey);
	
		// Build expected modifier flags
		const expectsCtrl = this.modifiers.includes('ctrl');
		const expectsShift = this.modifiers.includes('shift');
		const expectsAlt = this.modifiers.includes('alt');

		// Check if required modifiers are pressed
		const modifiersMatch =
		  (!expectsCtrl || event.ctrlKey || event.metaKey) &&
		  (!expectsShift || event.shiftKey) &&
		  (!expectsAlt || event.altKey);
	
		// Count how many are pressed and compare
		const totalExpected = this.modifiers.length;
		const totalPressed =
		  (event.ctrlKey ? 1 : 0) +
		  (event.shiftKey ? 1 : 0) +
		  (event.metaKey ? 1 : 0) +
		  (event.altKey ? 1 : 0);
	
		const isHotkeyMatch =
		  key === expectedKey &&
		  modifiersMatch &&
		  totalExpected === totalPressed;
	
		if (isHotkeyMatch) {
		  event.preventDefault();
		  this.el.nativeElement.click();
		}

	}
}
