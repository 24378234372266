import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	inject,
	input,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Sanitizer,
	ViewChild
} from '@angular/core';
import {Subject} from "rxjs";
import {FormsModule} from "@angular/forms";
import {FlowbotToolbarComponent} from "../flowbot-toolbar/flowbot-toolbar.component";
import {FlowbotInputComponent} from "../flowbot-input/flowbot-input.component";
import {FlowbotMessagesComponent} from "../flowbot-messages/flowbot-messages.component";
import {NewChatHintsComponent} from "../new-chat-hints/new-chat-hints.component";

import {Router} from "@angular/router";
import {ChatsService, FlowbotDrawerService} from '@flow/services';
export interface Message {
  type: string;
  content: string;
  rawContent: string;
}

@Component({
	selector: 'flowbot-chat-dialog',
	standalone: true,
	imports: [
		FormsModule,
		FlowbotToolbarComponent,
		FlowbotInputComponent,
		FlowbotMessagesComponent,
		NewChatHintsComponent,
	],
	templateUrl: './flowbot-chat.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class FlowbotChatComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('messageContainer') messageContainer: ElementRef;
	@Input() chatId: string | null = null;
    searchInput = input<string>('');
	@Output() drawerToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Input() currentUrl: string = '';
	messages: Message[] = [];
    reasoningActive: boolean = false;
    reasoning: Message;
	loading = false;
	private _chatService = inject(ChatsService);
	private _unsubscribeAll: Subject<any>;
    questionSuggestions: any;
    sources: any;
    disabled: boolean;

	constructor(
		private sanitizer: Sanitizer,
		private _changeDetectorRef: ChangeDetectorRef,
		private _router: Router,
		private _flowDrawerService: FlowbotDrawerService,
	) {
		this._unsubscribeAll = new Subject();

	}

	ngOnInit(): void {
		this._chatService.triggerFunction$.subscribe((message: string) => {
			this.chatRequest(message);
		});
	}

	ngAfterViewInit(): void {
		this._changeDetectorRef.detectChanges();
	}


	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

    chatRequest(event: any) {
        this.disabled = true;
        this.loading = true;

        // Add user message to the chat
        this.messages.push({
            content: event,
            type: 'user',
            rawContent: ''
        });

        // Placeholder for bot response
        this.messages.push({
            content: '',
            type: 'flowBot',
            rawContent: '', // Raw content for tracking
        });

        const messageRequest = {
            chatId: this.chatId,
            currentUrl: this.currentUrl,
            limit: 10,
            maxTokens: 50000,
            query: event,
        };

        let accumulatedText = ''; // Accumulate text properly
        let formattedText = ''; // Store progressively formatted text
        let inCodeBlock = false; // Track code block mode
        let buffer = ''; // Temporary buffer for line processing
        const lastMessage = this.messages[this.messages.length - 1];

        this._chatService
            .streamFlowAssistant(
                messageRequest.chatId,
                messageRequest.currentUrl,
                messageRequest.limit,
                messageRequest.maxTokens,
                messageRequest.query
            )
            .subscribe({
                next: (chunk) => {
                    if (this.chatId === null && chunk.data.flowAssistant.context) {
                        this.chatId = chunk.data.flowAssistant.context.chat_id;
                        this._changeDetectorRef.detectChanges();
                    }

                    const flowAssistantData = chunk.data.flowAssistant;
                    if (flowAssistantData.content && flowAssistantData.id !== 'reasoning_step') {
                        this.loading = false;
                        this.reasoningActive = false;

                        // Append streamed content
                        accumulatedText += flowAssistantData.content;

                        // Process and format **only new** streamed text
                        buffer += flowAssistantData.content;
                        const lines = buffer.split(/\n|↵/);

                        // Format complete lines and keep the unfinished one in buffer
                        for (let i = 0; i < lines.length - 1; i++) {
                            formattedText += this.formatLine(lines[i], inCodeBlock);
                        }
                        buffer = lines[lines.length - 1]; // Store any unfinished line

                        lastMessage.content = formattedText + buffer; // Update UI
                        this._changeDetectorRef.detectChanges();
                    }

                    // Handle reasoning response
                    if (flowAssistantData.content && flowAssistantData.id === 'reasoning_step') {
                        this.loading = false;
                        this.reasoning = flowAssistantData.content;
                        this.reasoningActive = true;
                        this._changeDetectorRef.detectChanges();
                    }

                    // Update suggestions
                    if (flowAssistantData.questionSuggestions) {
                        this.questionSuggestions = flowAssistantData.questionSuggestions;
                        this._changeDetectorRef.detectChanges();
                    }

                    // Update sources
                    if (flowAssistantData.sources) {
                        this.sources = flowAssistantData.sources;
                        this._changeDetectorRef.detectChanges();
                    }

                    this._changeDetectorRef.markForCheck();
                },
                error: (err) => {
                    this.loading = false;
                    lastMessage.content = 'Error Retrieving Response. Please try again.';
                    this._changeDetectorRef.detectChanges();
                    console.error('Streaming error:', err);
                },
                complete: () => {
                    this.disabled = false;

                    // Format the last part of the text
                    if (buffer.trim()) {
                        formattedText += this.formatLine(buffer, inCodeBlock);
                    }
                    lastMessage.content = formattedText; // Final UI update

                    this._changeDetectorRef.detectChanges();
                },
            });
    }

    /**
     * Formats a single line dynamically as it streams
     */
    formatLine(line: string, inCodeBlock: boolean): string {
        line = line.trim();

        // Handle code blocks
        if (line.startsWith('```')) {
            inCodeBlock = !inCodeBlock;
            return inCodeBlock ? `<pre class="bg-gray-900 text-white p-3 rounded-lg"><code>` : `</code></pre>`;
        }

        if (inCodeBlock) {
            return `${line}<br>`; // Keep formatting inside code blocks
        }

        // Bold, Italics, Inline Code
        line = line.replace(/\*\*(.*?)\*\*/g, '<strong class="font-bold">$1</strong>'); // Bold
        line = line.replace(/\*(.*?)\*/g, '<em class="italic">$1</em>'); // Italics
        line = line.replace(/`([^`]+)`/g, '<code class="bg-gray-200 px-1 py-0.5 rounded-md text-sm">$1</code>'); // Inline code

        // Headings
        if (line.startsWith('# ')) {
            return `<h1 class="text-2xl font-bold my-2">${line.replace('# ', '')}</h1>`;
        } else if (line.startsWith('## ')) {
            return `<h2 class="text-xl font-semibold my-2">${line.replace('## ', '')}</h2>`;
        } else if (line.startsWith('### ')) {
            return `<h3 class="text-lg font-medium my-2">${line.replace('### ', '')}</h3>`;
        }

        // Blockquotes
        if (line.startsWith('>')) {
            return `<blockquote class="border-l-4 border-gray-500 pl-4 italic">${line.replace('> ', '')}</blockquote>`;
        }

        // Ordered List (Numbered)
        /*if (/^\d+\./.test(line)) {
            return `<ol class="list-decimal ml-5"><li>${line.replace(/^\d+\.\s/, '')}</li></ol>`;
        }*/

        return `<p>${line}</p>`; // Default paragraph formatting
    }


    scrollToBottom(): void {
        console.log('scrollToBottom');
		setTimeout(() => {
			this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
		}, 0);
	}



}
